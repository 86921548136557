import {
    AuthorizationService,
    AxiosInstance,
    DataAccessService,
    EventService,
    PowerBiService,
} from '@price-for-profit/micro-services';
import { NavigationService } from '@price-for-profit/drive-account-navigation';
import { ActivityTrackingService } from '@price-for-profit/user-activity-tracking';

export function createServiceContext(appConfig: drive.AppConfig, httpClient: AxiosInstance): drive.ServiceContext {
    const eventService = new EventService(httpClient);
    const das = new DataAccessService(httpClient);
    const navigationService = new NavigationService(appConfig.clientId, das);
    const authorizationService = new AuthorizationService(httpClient);
    const powerBiService = new PowerBiService(httpClient);
    const activityTrackingService = new ActivityTrackingService(eventService);
    return {
        appConfig,
        eventService,
        authorizationService,
        powerBiService,
        navigationService,
        activityTrackingService,
    };
}
